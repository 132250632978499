<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :search-tips="$t('kiosk.nursing_work_log_search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      >
        <template #taskStatus="{props}">
          {{ $t('kiosk.taskStatus' + props.formattedRow[props.column.field]) }}
        </template>
      </table-content>

      <b-modal
        ref="modalTask"
        :title="$t('kiosk.tasks')"
        :ok-title="$t('common.confirm')"
        :cancel-title="$t('common.cancel')"
        @show="handleModalShow"
        @ok="handleModalOk"
        @hidden="handleModalHidden"
        no-close-on-backdrop
      >
        <component
          v-if="currentTask"
          :is="'nw-form-' + currentTask.taskType"
          v-model="currentTask.formData"
          :select-options="tasksSelectOptions"
          ref="nwFormComp"
        />
      </b-modal>
    </template>
  </page-content>
</template>

<script>
import common from "@/common";
import PageContent from "@/components/PageContent.vue";
import TableSearch from "@/components/TableSearch.vue";
import TableHeader from "@/components/TableHeader.vue";
import TableContent from "@/components/TableContent.vue";
import SlotFloor from "@/views/bed/SlotFloor.vue";
import kskNursingWorkMixin from "@/views/kiosk/mixins/kskNursingWork/kskNursingWorkMixin";

export default {
  name: "kioskNursingWorkLog",
  mixins: [kskNursingWorkMixin],
  components: {SlotFloor, PageContent, TableContent, TableHeader, TableSearch},
  data() {
    return {
      // Table
      title: common.getMenuName('kioskNursingWorkLog'),
      header_actions: [],
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'kiosk.created_time',
          field: 'createDateTime',
          width: '150px',
          change: function (value) {
            return common.transDateTime(value)
          }
        },
        {
          label: 'kiosk.measure_time',
          field: 'measureTime',
          width: '150px',
          type: 'number',
          change: this.changeTimeFormat,
        },
        {
          label: 'member.chineseName',
          field: 'memberName',
          width: '180px',
          changeHTML: function (value, row) {
            let avatar
            if (row.memberPhoto) {
              avatar = common.getServerUrl() + row.memberPhoto
            } else {
              avatar = require('@/assets/images/user.png')
            }
            return `
              <div class="d-flex align-items-center">
                <div class="b-avatar badge-secondary rounded-circle default-photo-bg mr-05">
                  <span class="b-avatar-img">
                    <img src="${avatar}" alt="">
                  </span>
                </div>
                <div>${value}</div>
              </div>
            `
          }
        },
        {
          label: 'kiosk.taskName',
          field: 'taskName',
          width: '110px',
        },
        {
          label: 'common.staff',
          field: 'staffName',
          width: '110px',
        },
        {
          label: 'kiosk.taskStatus',
          field: 'status',
          width: '110px',
          type: 'slot',
          slot: 'taskStatus',
        },
        {
          label: 'common.action',
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'NursingWorkLog_Edit'
        },
        {
          text: 'common.delete',
          variant: 'danger',
          fun: 'deleteData',
          permission: 'NursingWorkLog_Delete'
        }
      ],
      rows: [],
      total_rows: 0,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      current_page: 1,
      sort_condition: {},
      view_permission: common.checkPermission('Kiosk_Menu_NursingWorkLog'),

      // Filter
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'floorID',
          label: 'common.floor',
          type: 'select',
          options: [],
        },
        {
          field: 'roomID',
          label: 'common.room',
          type: 'select',
          options: []
        },
        {
          field: 'memberCode',
          label: 'common.member',
          type: 'select',
          options: [],
          searchable: true
        },
        {
          field: 'staffCode',
          label: 'common.staff',
          type: 'select',
          options: [],
          searchable: true
        },
        {
          field: 'taskType',
          label: 'kiosk.taskName',
          type: 'select',
          options: []
        },
      ],
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        floorID: common.getCustomConfigByKey('floorID'),
        roomID: common.getCustomConfigByKey('roomID'),
        memberCode: common.getCustomConfigByKey('memberCode'),
        staffCode: common.getCustomConfigByKey('staffCode'),
        taskType: this.$route.query.taskType ?? null,
      },

      // Modal
      editingLog: null,
    }
  },

  mounted() {
    if (!common.isAdmin()) {
      common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
      common.getSearchMemberOptions('memberCode', common.getRole().companyID, common.getRole().facilityID, this)
      common.getSearchStaffOptions('staffCode', common.getRole().companyID, common.getRole().facilityID, this)
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      common.getSearchFloorOptions('floorID', common.getAdminFacilityId(), this)
      common.getSearchMemberOptions('memberCode', common.getAdminCompanyId(), common.getAdminFacilityId(), this)
      common.getSearchStaffOptions('staffCode', common.getAdminCompanyId(), common.getAdminFacilityId(), this)
    }
    common.getSearchRoomOptions('roomID', this.search_default.floorID, this)
    this.getSearchTaskTypeOptions('taskType', this)

    this.getList()
  },

  methods: {
    // Search
    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
        memberCode: vmOfSearch.list.memberCode,
        staffCode: vmOfSearch.list.staffCode,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },
    getSearchTaskTypeOptions(field_name, obj) {
      return this.getTaskTypeOptions()
        .then(res => {
          common.buildSearchOptions(field_name, obj, res)
          return res
        })
    },
    getTaskTypeOptions() {
      return common.apiGetData('/kioskmanage/GetTaskType')
        .then(res => {
          return common.getSelectOptions(res.data, 'statusDesc', 'statusCode')
        })
    },

    // Table
    runTableAction: function (action, data) {
      this[action](data)
    },
    editData: function (data) {
      this.currentTaskType = data.taskType

      common.apiGetData(`/kioskmanage/Get${this.currentTask.requestTaskType}Detail?measureID=${data.id}`)
        .then(res => {
          this.editingLog = Object.assign(data, res.data)
          this.$refs.modalTask.show()
        })
    },
    deleteData: function (data) {
      const requestTaskType = this.tasks.find(t => t.taskType === data.taskType).requestTaskType

      common.showConfirm(
        this.$t('common.delete_tips') + data.taskName,
        '',
        () => {
          common.apiPostData(`/kiosk/Delete${requestTaskType}?measureID=${data.id}`)
            .then(() => {
              common.showToast(this.$t('common.delete_success'))
              this.getList()
            })
        })
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    getList: async function () {
      if (!this.view_permission) return

      const api = '/kioskmanage/GetMeasureTaskSummaryList'
      const search = this.getSearchCondition()
      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)
      const { data } = await common.apiGetData(url)

      // Assign value to current component
      this.total_rows = data.total
      this.rows = data.measureTaskSummary
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    changeTimeFormat(time) {
      if (time === null) {
        return ''
      } else {
        return this.$moment(time)
          .format('YYYY-MM-DD HH:mm:ss')
      }
    },
  }
}
</script>

<style scoped>

</style>